import React from "react";
import { Box, Link, Typography, Container } from "@mui/material";
import AffiliateView from "../components/affiliateView";
import AuthorComponent from "../components/authorComponent";
import Seo from "../components/seo";
import SocialMediaComponent from "../components/socialMediaComponent";
import { SubHeadline } from "../styles/globalStyles";
import Layout from "../styles/layout";

function inlineLink(url, title) {
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      {title}
    </Link>
  );
}

export default function About({ props, location }) {
  return (
    <Layout location={location}>
      <Seo
        title="About the author - iOS development on tanaschita.com"
        description="Since 2019, I'm publishing articles on iOS and Swift topics here on
        tanaschita.com. I'm passionate about programming and at least as much
        about teaching programming."
      />
      <Container>
        <Typography variant="h1">About the author</Typography>
        <SubHeadline>That's me</SubHeadline>
        <AuthorComponent isLongDescription="true"></AuthorComponent>
        <Box height="24px"></Box>
        <Typography>
          Since 2019, I'm publishing articles on iOS and Swift topics here on
          tanaschita.com. I’m passionate about programming and at least as much
          about teaching programming. Besides running this site, I'm freelancing
          as an iOS developer since 2012. Since then, I worked for many
          companies of different sizes on complex user-focused software
          applications.
        </Typography>
        <SubHeadline>Say hi</SubHeadline>
        <SocialMediaComponent></SocialMediaComponent>
        <Box height="24px"></Box>
        <Typography>
          • Want to stay updated? Then follow me on{" "}
          {inlineLink("https://twitter.com/tanaschita", "twitter")}. I'm
          regularly posting there about new articles and iOS insights. Or
          subscribe for a {inlineLink("/newsletter/", "newsletter")}.
        </Typography>
        <Typography>
          • Want to hire me for iOS development or have any questions or
          feedback? Then feel free to connect via{" "}
          {inlineLink(
            "https://www.linkedin.com/in/nataschafadeeva/",
            "linkedin"
          )}{" "}
          or to write me an {inlineLink("mailto:tanaschita@gmail.com", "email")}
          .
        </Typography>
        <Typography>
          • Would you like to support me? Then{" "}
          {inlineLink(
            "https://www.buymeacoffee.com/tanaschita",
            "buy me a coffee"
          )}
          .
        </Typography>
        <Typography>
          • Would you like to advertise on tanaschita.com? Checkout the{" "}
          {inlineLink("/sponsorship/", "sponsorship")} page to find out more.
        </Typography>
        <Box height="24px"></Box>
        <SubHeadline>
          Articles I am honored to have been mentioned in
        </SubHeadline>
        <Typography>
          • Recommendations in weekly newsletter on{" "}
          {inlineLink("https://iosdevweekly.com", "iosdevweekly.com")}{" "}
        </Typography>
        <Typography>
          • Recommendations from{" "}
          {inlineLink("https://www.midnightswift.com", "midnightswift.com")}{" "}
        </Typography>

        <Typography>
          • Recommendations in weekly newsletter on{" "}
          {inlineLink(
            "https://swiftlyrush.curated.co",
            "swiftlyrush.curated.co"
          )}
        </Typography>
        <Typography>
          • Recommendations in weekly newsletter on{" "}
          {inlineLink("https://ios-goodies.com", "ios-goodies.com")}
        </Typography>
        <Typography>
          • Recommendations in SwiftLee weekly newsletter on{" "}
          {inlineLink(
            "https://www.avanderlee.com/swiftlee-weekly/",
            "avanderlee.com"
          )}
        </Typography>
        <Typography>
          • "Celebrating Women’s History Month" on{" "}
          {inlineLink(
            "https://swift.org/blog/womens-history-month/",
            "swift.org"
          )}{" "}
        </Typography>
        <Typography>
          • "Swift Community Awards" on{" "}
          {inlineLink(
            "https://www.hackingwithswift.com/awards",
            "hackingwithswift.com"
          )}
        </Typography>
        <Typography>
          • "Freelancing Women In Tech" on{" "}
          {inlineLink(
            "https://landing.expertlead.com/blog/freelancing-women-in-tech-0?hs_preview=WycVRMch-34316972140",
            "expertlead.com"
          )}
        </Typography>
        <Box height="24px"></Box>
        <AffiliateView type="sponsor"></AffiliateView>
        <Box height="24px"></Box>
      </Container>
    </Layout>
  );
}
